import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { PostPreview, Layout, Pagination, SectionHeader } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Archive = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData location={location} />
      <Layout isHome>
        <SectionHeader sectionTitle={"Archive"}/>
        <div className="Index__OlderNotesContainer">
          {posts.map(({ node }, index) => {
            return <PostPreview key={node.id} post={node} loading="eager"/>
          })}
        </div>
        <Pagination pageContext={pageContext} />
      </Layout>
    </>
  )
}

Archive.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default Archive

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: { featured: { eq: false } },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
